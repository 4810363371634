import React from "react";
import {
  HStack,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  Heading,
  Flex,
  Box,
  Avatar,
  Alert,
  Icon,
  Stack,
} from "@chakra-ui/react";
import { FaExclamationCircle } from "react-icons/fa";
import Link from "./Link";
import Head from "./Head";
import { Footer } from "./Footer";
import Image from "next/image";
import { DefaultCharacter } from "../assets";
import { useUserInfo } from "../hooks/useUserInfo";
import { P } from "./Type";
import { useRequest } from "../contexts/request";
import { Announcement } from "./Announcement";
import { Trans } from "@lingui/macro";

const Profile = ({ profilePicture, username }) => {
  const { logout } = useRequest();
  return (
    <Menu allowToggle as="button" w="10px">
      <MenuButton
        backgroundColor={"whitish"}
        boxShadow={"buttonRaised"}
        paddingY={2}
        paddingX={5}
        border="default"
        borderRadius="md"
        transition="box-shadow .1s , transform .2s "
        _active={{
          boxShadow: "pressed",
        }}
        _disabled={{
          cursor: "not-allowed",
        }}
        textDecoration="none"
        alignItems="center"
      >
        <HStack>
          <Avatar name={username} src={profilePicture} />
          <P mb={0}>{username}</P>
        </HStack>
      </MenuButton>
      <MenuList
        backgroundColor={"whitish"}
        boxShadow={"buttonRaised"}
        border="default"
        borderRadius="md"
        transition="box-shadow .1s , transform .2s "
        _active={{
          boxShadow: "pressed",
        }}
        _disabled={{
          cursor: "not-allowed",
        }}
        textDecoration="none"
        alignItems="center"
      >
        <Link href="/profile">
          <MenuItem>Profile</MenuItem>
        </Link>
        <MenuItem
          onClick={() => {
            logout();
          }}
        >
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

const OnboardingToast = ({ id }) => {
  return (
    <Alert backgroundColor="red.500" rounded={"md"} padding="2" color="white">
      <Stack
        spacing={3}
        direction={["column", "column", "row"]}
        alignItems={"left"}
        justifyContent={"center"}
      >
        <HStack>
          <Icon as={FaExclamationCircle} />
          <Box fontWeight={"bold"} fontSize={"md"}>
            <Trans>Akun belum aktif.</Trans>
          </Box>
        </HStack>
        <P mb={0} fontSize="md">
          <Trans>
            Silahkan masukkan tujuan cashout{" "}
            <Link
              href="/transactions/cashout"
              as="span"
              textDecoration="underline"
            >
              disini
            </Link>{" "}
            untuk mengaktifkan
          </Trans>
        </P>
      </Stack>
    </Alert>
  );
};

const AppShell = ({ title = "Saweria", children, ...props }) => {
  const {
    data: {
      username,
      profile_picture: profilePicture,
      has_bank_info: hasBankInfo = true,
    } = {},
  } = useUserInfo();

  return (
    <Flex
      direction="column"
      alignItems="center"
      w={"100%"}
      minH={"100vh"}
      {...props}
    >
      <Head title={title} />
      <Announcement />
      <Flex
        direction="column"
        data-testid="layout"
        w={["100%", "100%", "75%"]}
        flexGrow={1}
        padding={[3, 0, 0]}
      >
        {hasBankInfo ? null : <OnboardingToast />}
        <Flex mb={["2em"]} mt={3} justifyContent="space-between">
          <Link href="/admin" display="flex" alignItems="end">
            <Box display={["none", "block"]} w={["50%", "10%"]}>
              <Image alt="Saweria Logo" src={DefaultCharacter} />
            </Box>
            <Heading
              as="h1"
              fontSize={["2xl", "3xl"]}
              textTransform="none"
              p="0"
              m="0"
              color="blackish"
              fontWeight="light"
              textDecoration="none"
            >
              saweria.co
            </Heading>
          </Link>
          <Profile profilePicture={profilePicture} username={username} />
        </Flex>

        <Flex flexGrow={1} direction="column">
          {children}
        </Flex>
        <Footer flexBasis={"8rem"} flexShrink={0} marginTop={4}></Footer>
      </Flex>
    </Flex>
  );
};

export { AppShell, Profile };
