import React from "react";
import { useQuery, queryCache } from "react-query";
import { useRequest } from "../contexts/request";

const useUserInfo = (props = {}, handleError) => {
  const { get, userInfo, setUserInfo } = useRequest();
  return useQuery(
    "userInfo",
    async (key) => {
      const { json } = await get(`users`, {}, {}, handleError);
      const { data } = json;
      setUserInfo({ ...userInfo, ...data });
      return data;
    },
    {
      enabled: Object.keys(userInfo).length !== 0,
      staleTime: 30000,
      retry: (failureCount, error) => {
        const {
          response: { status },
        } = error;

        if (status === 401) {
          return false;
        }
      },
      ...props,
    }
  );
};

const invalidateUserInfo = () => {
  queryCache.invalidateQueries("userInfo");
};

export { useUserInfo, invalidateUserInfo };
